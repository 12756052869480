import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";
import image from "../../../assets/images/remote-resume.png";
import { colors, media } from "../../../constants/theme";
import { AFFILIATES } from "../../../constants/affiliates";
import { TrackClick } from "../../../utils/analytics";

const Wrapper = styled.div`
  background-color: ${colors.backgroundBlue};
`;

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 24px;
  padding-right: 24px;
  ${media.desktop`
    padding-top: 180px;
    padding-bottom: 240px;
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
  `}
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  ${media.desktop`
    padding: 60px;
  `}
`;
const SubHeading = styled.p`
  color: ${colors.linkBlue};
  margin-bottom: 8px;
  margin-top: 40px;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const Heading = styled.h3`
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.05em;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 1.6em;
`;

const Button = styled.a`
  background: ${colors.primaryRed};
  color: #fff;
  font-weight: 600;
  width: 240px;
  height: 50px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Inline = styled.a`
  color: ${colors.linkBlue};
`;

export const RemoteResume = props => (
  <Wrapper>
    <Container>
      <LazyLoad offset={728} height={528} once>
        <img src={image} alt="Remote Resume" />
      </LazyLoad>
      <Content>
        <SubHeading>Remote Resume</SubHeading>
        <Heading>Stand out from the crowd</Heading>
        <Text>
          We’ve teamed up with{" "}
          <Inline
            href={AFFILIATES.resumeio_home}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={e => {
              e.preventDefault();
              TrackClick({
                category: "Affiliates",
                action: props.tracking.action,
                label: "Inline Link - resume.io"
              });
              window.open(AFFILIATES.resumeio_home);
            }}
          >
            Resume.io
          </Inline>{" "}
          to help you create a resume that stands out from the crowd and gets
          your application further with remote hirers.
        </Text>
        <Button
          href={AFFILIATES.resumeio_signup}
          target="_blank"
          rel="noopener noreferrer nofollow"
          onClick={e => {
            e.preventDefault();
            TrackClick({
              category: "Affiliates",
              action: props.tracking.action,
              label: "Button - Create a Remote Resume"
            });
            window.open(AFFILIATES.resumeio_signup);
          }}
        >
          Create a Remote Resume
        </Button>
      </Content>
    </Container>
  </Wrapper>
);
