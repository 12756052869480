import React from "react";
import styled from "styled-components";
import { media, colors } from "../../../constants/theme";
import { Link } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { TrackClick } from "../../../utils/analytics";
import { PARTNER_PATHS } from "../../../constants/partner-paths";

// Comment to kick off build

const HeroWrapper = styled.div`
  min-height: calc(100vh - 112px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroContent = styled.div`
  color: #1b1b1b;
  text-align: center;
  padding: 0 16px;
  z-index: 4;
  position: relative;
  ${media.desktop`
    padding: 0 24px;    
  `}
`;

const HeroLink = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 24px;
  margin-left: 8px;
  margin-right: 8px;

  > div,
  > div a {
    background: ${colors.primaryRed};
    color: #fff;
    font-weight: 600;
    width: 200px;
    height: 50px;
    line-height: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Headline = styled.h1`
  font-size: 32px;
  letter-spacing: -0.05em;
  margin: 0;
  line-height: 1em;
  margin-bottom: 14px;
  text-transform: capitalize;

  ${media.desktop`
    font-size: 40px;
  `}
`;
const SubHeading = styled.p`
  font-size: 16px;
  color: #b0b0b0;
  font-weight: 300;
  line-height: 23px;
  margin: 0;
  ${media.desktop`
    font-size: 18px;
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
  `}
`;

const NewProduct = styled.a`
  display: block;
  margin-top: 48px;
  color: ${colors.linkBlue};
`;

const NewBadge = styled.div`
  background-color: ${colors.linkBlue};
  color: white;
  position: absolute;
  padding: 4px 12px;
  border-radius: 12px;
  top: -11px;
  right: 8px;
  font-size: 12px;
`;

const StyledLink = styled.a`
  svg {
    max-width: 16px;
    margin-left: 4px;
  }
`;

export const NewHero = (props) => {
  const setHeading = () => {
    const { category } = props;
    if (category === "customerservice") {
      return "customer service";
    }
    if (category === "devopsandsysadmin") {
      return "devops and sysadmin";
    }
    if (category === "seo") {
      return "SEO";
    }
    if (category === "manager") {
      return "manager/exec";
    }
    if (category === "hr") {
      return "HR";
    }
    if (category === "asp net") {
      return "ASP.net";
    }
    if (category === "aws") {
      return "AWS";
    }
    if (category === "node") {
      return "Node JS";
    }
    if (category === "react") {
      return "React Developer";
    }
    if (category === "java") {
      return "Java Developer";
    }
    if (category === "php") {
      return "PHP";
    }
    if (category === "ios") {
      return "iOS Developer";
    }

    return category;
  };

  const isHome = props.isHome;

  return (
    <HeroWrapper>
      <HeroContent>
        <Headline>
          {props.title ? (
            props.title
          ) : (
            <>
              {props.noRemote ? "" : "Remote"} {setHeading()} Jobs{" "}
              {isHome && "That Fit Your Life"}
            </>
          )}
        </Headline>
        <SubHeading>
          {props.subtitle
            ? props.subtitle
            : "Discover fully and partially remote jobs from the greatest remote working companies"}
        </SubHeading>
        <LinksWrapper>
          <HeroLink>
            <div>
              <Link to={paths.all_jobs}>Remote Jobs</Link>
            </div>
          </HeroLink>
          <HeroLink>
            <div>
              <Link
                to={paths.power_search}
                onClick={() =>
                  TrackClick({
                    category: "PowerSearch",
                    action: "PowerSearch - Homepage - Hero",
                    label: "PowerSearch - Homepage",
                  })
                }
              >
                PowerSearch
              </Link>
            </div>
          </HeroLink>

          <HeroLink>
            <div>
              <NewBadge>New</NewBadge>
              <StyledLink
                href={PARTNER_PATHS.HUMAN}
                rel="noopener"
                target="_blank"
                onClick={() =>
                  TrackClick({
                    category: "Human",
                    action: "Human - Homepage - Hero",
                    label: "Human - Homepage",
                  })
                }
              >
                Human
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M6.22 8.72a.75.75 0 0 0 1.06 1.06l5.22-5.22v1.69a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0 0 1.5h1.69L6.22 8.72Z" />
                  <path d="M3.5 6.75c0-.69.56-1.25 1.25-1.25H7A.75.75 0 0 0 7 4H4.75A2.75 2.75 0 0 0 2 6.75v4.5A2.75 2.75 0 0 0 4.75 14h4.5A2.75 2.75 0 0 0 12 11.25V9a.75.75 0 0 0-1.5 0v2.25c0 .69-.56 1.25-1.25 1.25h-4.5c-.69 0-1.25-.56-1.25-1.25v-4.5Z" />
                </svg>
              </StyledLink>
            </div>
          </HeroLink>
        </LinksWrapper>
        <NewProduct
          href={PARTNER_PATHS.WORKSTER}
          target="_blank"
          rel="noopener"
          onClick={() => {
            TrackClick({
              category: "PortfolioBusiness",
              action: "Workster - Homepage - Hero",
              label: "Workster - Homepage",
            });
          }}
        >
          Looking for remote jobs in the USA? <u>Check out Workster</u>
        </NewProduct>
      </HeroContent>
    </HeroWrapper>
  );
};
